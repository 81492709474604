import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';

import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { COOKIE_OPTIONS, COOKIE_NAME, IS_PRODUCTION } from '../constants';
import { SessionQuery, useSessionQuery } from '../graphql/operations/users.generated';
import { segmentIdentify } from '../utils/analytics';

export const SessionContext = React.createContext<{
  session: SessionQuery['session'] | null;
  loading: boolean;
}>({
  session: null,
  loading: false,
});

const useSessionProvider = (): {
  session: SessionQuery['session'];
  loading: boolean;
} => {
  const router = useRouter();
  const [cookies, , removeCookie] = useCookies();
  const { data, loading } = useSessionQuery({
    skip: isEmpty(cookies[COOKIE_NAME]) || router.asPath.includes('/e/'),
    onError: (err) => {
      if (err.networkError) {
        return null;
      }

      removeCookie(COOKIE_NAME, COOKIE_OPTIONS);

      return router.push({ pathname: '/login', query: { returnTo: router.asPath } });
    },
    onCompleted: async (response) => {
      if (IS_PRODUCTION) {
        const LogRocket = await import(/* webpackChunkName: "logrocket" */ 'logrocket');
        LogRocket.identify(response?.session?.user?.id, {
          name: response?.session?.user?.name,
          email: response?.session?.user?.email,
          // @ts-expect-error
          organizationId: response?.session?.organization?.id,
        });
        segmentIdentify(response?.session?.user?.id, {
          name: response?.session?.user?.name,
          email: response?.session?.user?.email,
          organizationId: response?.session?.organization?.id,
          company: {
            id: response?.session?.organization?.id,
            name: response?.session?.organization?.name,
          },
          logrocketSessionUrl: LogRocket.sessionURL,
        });
      }

      Sentry.setUser({
        email: response?.session?.user?.email,
        id: response?.session?.user?.id,
        organization: response?.session?.organization?.name,
      });
    },
  });
  // @ts-expect-error
  return { session: data?.session, loading };
};

const SessionProvider = ({ children }: { children: ReactNode }) => {
  const value = useSessionProvider();

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
